























































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver } from 'vee-validate';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import FormTextArea from '@/components/common/form-elements/FormTextArea.vue';
import Modal from '@/components/common/Modal.vue';

@Component({
  components: {
    FormInput,
    FormTextArea,
    ValidationObserver,
    Modal,
  },
})
export default class TabMessaging extends Vue {
  @Action sendNotification!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @StateClass notification!: State['notification'];

  title: string = '';
  body: string = '';
  showModal: boolean = false;

  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Watch('notification', { deep: true })
  onGeneralSettingsChanged(newState: State['notification']): void {
    if (newState.status && newState.status === DataContainerStatus.Error) {
      this.addToastMessage({
        text: newState.error,
        type: 'danger',
      });
    }

    if (newState.status && newState.status === DataContainerStatus.Success) {
      this.form.reset();
      this.addToastMessage({
        text: 'Your notification has been sent.',
        type: 'success',
      });
    }

    this.showModal = false;
  }
}
